<script>
  import ErpSField from 'uloc-vue-plugin-erp/components/form/SimpleField'
  import ErpInput from 'uloc-vue-plugin-erp/components/form/input'
  import {updatePassword} from '../../../domain/usuario/services'
  import {
    WindowContent,
    WindowBody
  } from 'uloc-vue-plugin-erp'
  import WindowLoading from '../../layout/window/Loading'
  import TimerCloseWindow from '../../layout/window/TimerClose'
  import WindowSuccess from '../../layout/window/Success'

  export default {
    name: 'alterar-senha-form',
    components: {WindowBody, WindowContent, WindowSuccess, WindowLoading, TimerCloseWindow, ErpInput, ErpSField},
    data () {
      return {
        data: {
          senhaAtual: '',
          novaSenha: ''
        },
        confirmarNovaSenha: '',
        success: false,
        errors: null,
        status: 'Alterando senha',
        loading: false
      }
    },
    computed: {
      confirmacaoValida () {
        return this.data.novaSenha === this.confirmarNovaSenha
      }
    },
    mounted () {
      this.$refs.senhaAtual.focus()
    },
    methods: {
      submit () {
        this.loading = true
        setTimeout(() => {
          this.status = 'Aguarde...'
        }, 1500)
        updatePassword(this.$uloc.auth.session.user.id, this.data)
          .then(response => {
            console.log(response.data)
            this.loading = false
            this.success = true
            this.data.senhaAtual = ''
            this.data.novaSenha = ''
          })
          .catch(error => {
            this.errors = error.data.message
            this.loading = false
          })
      }
    }
  }
</script>

<template>
    <window-content v-if="!success && !loading && !errors">
        <window-body class="wrapper padder-lg">
            <div class="text-center">
                <u-icon name="key" color="positive" type="fa" icon-style="solid" class="fa-8x text-grey-7" />
            </div>
            <form @submit.prevent="submit" class="form-dados m-t">
                <h5 class="subdiv text-center m">Alteração de senha</h5>
                <p class="igroup">
                    <erp-s-field label="Senha atual">
                        <erp-input size="2" type="password" v-model="data.senhaAtual" ref="senhaAtual" required />
                    </erp-s-field>
                </p>
                <p class="igroup">
                    <erp-s-field label="Nova senha">
                        <erp-input size="2" type="password" v-model="data.novaSenha" required />
                    </erp-s-field>
                </p>
                <p class="igroup">
                    <erp-s-field label="Confirmar nova senha">
                        <erp-input size="2" type="password" v-model="confirmarNovaSenha" required />
                    </erp-s-field>
                </p>
                <div class="text-red-7 m-b" v-if="!confirmacaoValida">
                    <u-icon name="times" color="negative" type="fa" icon-style="solid" /> As senhas não conferem!
                </div>
                <u-btn label="Enviar" type="submit" rounded push icon-type="fa" icon="check" no-caps class="pull-right text-blue-6" :disabled="!confirmacaoValida"/>
            </form>
        </window-body>
    </window-content>
    <window-success v-else-if="success">
        <div class="text-center">
            <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
        </div>
        <h5 class="m-t">Senha atualizada!</h5>
        <div class="text-center m-t">
            <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
        </div>
    </window-success>
    <window-loading :errors="errors" :status="status" v-else />
</template>
